<template>
  <div class="container">
    <PublicHeader :totalName="$t('share').title" />
    <div class="share-popup">
      <div class="cord-box">
        <qrcode-vue
          :value="link"
          :size="size"
          level="H"
          class="qrcode"
        ></qrcode-vue>
      </div>
      <van-button size="mini" type="info" color="#8e1473" @click="getShareLink">{{
        $t('share').cp
      }}</van-button>
    </div>
    <span id="InvitationCode">{{ link }}</span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import userApi from '@/api/user'
import QrcodeVue from 'qrcode.vue'
import PublicHeader from '@/components/publicHeaderNew'
export default {
  components: {
    QrcodeVue,
    PublicHeader
  },
  data() {
    return {
      link: '',
      size: 150
    }
  },
  computed: {
    ...mapGetters(['basicConfig'])
  },
  mounted() {
    this.getUserInfo()
  },
  methods: {
    async getUserInfo() {
      this.userInfo = await userApi.userInfo()
      this.link =
        this.basicConfig.ShareDomain +
        '/#/Register?InvitationCode=' +
        this.userInfo.InvitationCode
    },
    getShareLink() {
      setTimeout(() => {
        this.$toast({
          type: 'success',
          message: this.$t('share').success,
          className: 'message',
          duration: '1000'
        })
      }, 200)
      const range = document.createRange()
      range.selectNode(document.getElementById('InvitationCode'))
      const selection = window.getSelection()
      if (selection.rangeCount > 0) selection.removeAllRanges()
      selection.addRange(range)
      document.execCommand('copy')
      selection.removeRange(range)
    }
  }
}
</script>

<style lang="less" scoped>
.share-popup {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  .cord-box {
    margin-bottom: 20px;
    padding: 5px;
    background: #fff;
    border-radius: 5px;
  }
}
#InvitationCode {
  position: absolute;
  left: -100vw;
}
</style>
